import dynamic from 'next/dynamic';
import React from 'react';

const AnalyticsDynamic = dynamic(
  () => import('@leuven2030/framework/Analytics/AnalyticsDynamic')
);

const Analytics = () => {
  return <AnalyticsDynamic />;
};
export default Analytics;
